<!--  -->
<template>
    <div class='subject-com-result-stars'>
		<div class="star" v-for="(v, i) in stars" :key="i" :class="[v.class, {'active': v.active}]"></div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            number: [Number, String]
        },
        data() {
            return {
                // defaultStar: ['left', 'center', 'right'],
                defaultStar: ['center', 'left', 'right'],
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            stars(){
				let {defaultStar, userStar} = this
				let rd = []
				for(let i in defaultStar){
					let tem = {class: defaultStar[i]}
					if((Number(i) + 1) <= userStar) tem.active = true
					rd.push(tem)
				}
				return rd
			},
			userStar(){
				let {number} = this
				return number || 0
			},
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .subject-com-result-stars{
        position: relative;
        display: flex;
        width: 100%;
        .star{
            width: 56px;
            height: 56px;
            background: url('@{assetsUrl}/img/subject/star_result.png');
            background-size: 100% 100%;
            position: absolute;
            left: 0px;
            right: 0px;
            margin: 0px auto;
        }
        .star.active{
            background: url('@{assetsUrl}/img/subject/star_result_active.png');
            background-size: 100% 100%;
        }
        .star.center{
            z-index: 2;
        }
        .star.left, .star.right{
            margin-top: 4px;
        }
        .star.left{
            transform: translateX(-34px) rotate(-15deg);
        }
        .star.right{
            transform: translateX(34px) rotate(15deg);
        }
    }
</style>