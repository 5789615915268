<!--  -->
<template>
    <div class='page-subject-result flex-center'>
        <div class="result-body">
            <div class="result-item a">
                <div class="result-star-box flex-center">
                    <stars :number="starNum"></stars>
                </div>
                <div class="result-status" :class="{'win': starNum > 0, 'lose': starNum <= 0}"></div>
            </div>
            <div class="result-item b flex-center">
                <div class="b-item a">
                    <img :src="`${PARAMS.assetsUrl}/img/subject/s9.png`" class="ex-icon">
                    <span class="text-normal">答对{{correctNum}}题</span>
                </div>
                <div class="b-item-line">
                    <div class="line"></div>
                </div>
                <div class="b-item b">
                    <img :src="`${PARAMS.assetsUrl}/img/subject/s10.png`" class="ex-icon">
                    <span class="text-normal">答错{{wrongNum}}题</span>
                </div>
            </div>
            <div class="result-item c">
                <dia-button type="cancel" shape="square" v-for="(v, i) in funListCul" :key="i" :disabled="v.isDisabled" @click="funHand(v)">
                    <span class="text-normal">{{v.title}}</span>
                </dia-button>
            </div>
        </div>
    </div>
</template>

<script>
    import stars from '../components/resultStar.vue'
    import apiClass from '@/API/page/subject'

    export default {
        name: '',
        props: {},
        data() {
            return {
                api: new apiClass(),
                funList: [
                    {title: this.pageText.t1, disabledCul: 'continueDisabled', fun: 'continue'},
                    {title: this.pageText.t2, fun: 'restart'},
                    {title: this.pageText.t3, fun: 'back'},
                ],
                puzzleId: this.$route.params.puzzleId,
                puzzleInfo: {},
                nextPuzzle: {},
                configList: [],
                puzzleCount: 0,
                nextNoticeState: false

            };
        },
        methods: {
            async pageInit(){
                await this.configInit()
                await this.puzzleInfoInit()
                // await this.nextPuzzleInit()
                await this.puzzleListInit()
                this.puzzleResultInit()
            },
            async puzzleListInit(){
                let {listParams} = this
                listParams.page = 0
                listParams.size = 10
                let res = await this.api.getSubjectDirectory(listParams)
                if(!res) return
                this.puzzleCount = res.puzzleCount
            },
            async configInit(){
                let res = await this.api.configLoad('puzzle')
                if(res) this.configList = res
            },
            async puzzleInfoInit(){
                let {usercode: user_code, puzzleId: puzzle_id} = this
                if(!user_code) return
                let res = await this.api.puzzleInfo({puzzle_id, user_code})
                if(!res) return
                this.puzzleInfo = res
            },
            back(){
                let {puzzleLevel: headVal, puzzleNo} = this.puzzleInfo
                // if(!headVal ||!puzzleNo) return
                headVal = headVal || 'G0'
                puzzleNo = puzzleNo || 1
                let page = this.getBackPage(puzzleNo)
                page--
                if(page < 0) page = 0
                let params = {page, headVal}, name = 'subject-list-full', type = 'replace'
                params = {routeData: this._common.encodeData(params)}
                // this._routeHand.goto({params, name, type})
                this._routeHand.back()
            },
            getBackPage(num){
                let {subjectPageSize: size} = this.PARAMS
                return Math.ceil(num / size)
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('subjectResultBack', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('subjectResultBack')
            },
            async puzzleResultInit(){
                let {usercode, puzzleId: puzzle_id} = this
                let res = await this.api.puzzleResult({usercode, puzzle_id})
            },
            funHand(val){
                let {fun} = val || {}
                if(!this[fun]) return
                this[fun](val)
            },
            async nextPuzzleInit(){
                let {
                    puzzleInfo: {
                        puzzleLevel: puzzle_level,
                        puzzleNo: puzzle_no
                    },
                    usercode: user_code
                } = this
                if(!user_code || !puzzle_level || !puzzle_no) return
                let res = await this.api.nextPuzzle({puzzle_level, puzzle_no, user_code})
                if(!res) return
                res = res || {}
                this.nextPuzzle = res
                return res
            },
            async continue(){
                if(this.continueDisabled) return
                let res = await this.nextPuzzleInit()
                if(!res) return
                if(res && !res.id){
                    this._common.confirm.open({
                        message: `暂无更多关卡，敬请期待`,
                        buttons: [
                            {title: '知道啦', type: 'confirm'}
                        ]
                    })
                    return
                }
                this.continueAction(res)
            },
            continueAction(res){
                let {id, puzzleId} = res || {}
                let rId = puzzleId || id
                let name = 'subject-action'
                let params = {puzzleId: rId}
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, params})
            },
            restart(){
                let {puzzleId} = this

                let name = 'subject-action'
                let type = 'replace'
                let params = {puzzleId}
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, params, type})
            },
            nextNotice(val){
                if(!val || this.nextNoticeState) return
                this.nextNoticeState = true
                let {puzzleLevel: level} = this.puzzleInfo || {} 
                this._common.confirm.open({
                    message: `恭喜你，${level}任务已完成`,
                    buttons: [
                        {title: '知道啦', type: 'confirm'}
                    ]
                })
            }
        },
        created() {
            this.eventSet()
            this.pageInit()
        },
        components: {
            stars
        },
        computed: {
            listParams(){
                let {app: {usercode: user_code} = {}} = this.$store.state
                let {school_id} = this.PARAMS
                let {
                    puzzleInfo: {puzzleLevel: puzzle_level} = {}
                } = this
                return {
                    // user_code: '123456',
                    user_code,
                    school_id,
                    puzzle_level,
                }
            },
            nextPuzzleNotice(){
                let {puzzleCount, puzzleInfo: {puzzleNo} = {}, starNum} = this
                if(puzzleCount === null || !puzzleNo) return false
                return puzzleNo == puzzleCount && starNum > 0
            },
            nextPuzzleLevel(){
                let {configList: list, puzzleInfo: {puzzleLevel} = {}} = this
                let data = {}
                for(let i in list){
                    let {resourceDetail: {value} = {}} = list[i]
                    if(value == puzzleLevel){
                        let tem = list[Number(i) + 1] || {}
                        data = tem.resourceDetail
                        break
                    }
                }
                return data
            },
            funListCul(){
                let {funList: list} = this
                let data = this._callFun.funhandAct.call(this, list)
                return data
            },
            continueDisabled(){
                return this.starNum <= 0
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            starNum(){
                let {userStarNum = 0} = this.puzzleInfo || {}
                return userStarNum
            },
            exerciseCount(){
                let {exerciseCount = 0} = this.puzzleInfo || {}
                return exerciseCount
            },
            correctNum(){
                let {correctCount = 0} = this.puzzleInfo || {}
                return correctCount
            },
            wrongNum(){
                let {incorrectCount = 0} = this.puzzleInfo || {}
                return incorrectCount
            },
            puzzleStatus(){
                let {userStarNum = 0} = this.puzzleInfo || {}
                return userStarNum
            }
        },
        watch: {
            nextPuzzleNotice: {
                handler(val){
                    this.nextNotice(val)
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(){
                    this.puzzleId = this.$route.params.puzzleId
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-subject-result{
        // padding-right: @app-padding-side;
        color: #333;
        .result-body{
            padding: 27px 16px 22px;
            width: 303px;
            height: 335px;
            background: #fff;
            border-radius: 16px;
            .result-item.a{
                position: relative;
                .result-status{
                    width: 100%;
                    height: 100px;
                }
                .result-status.win{
                    background: url('@{assetsUrl}/img/subject/sub_win.png');
                    background-size: 100% 100%;
                }
                .result-status.lose{
                    background: url('@{assetsUrl}/img/subject/sub_lose.png');
                    background-size: 100% 100%;
                }
                .result-star-box{
                    position: absolute;
                    width: 100%;
                    top: -11px;
                }
            }
            .result-item.b{
                position: relative;
                .b-item.a{
                    margin-right: 25px;
                }
                .b-item.b{
                }
                .b-item{
                    display: flex;
                    align-items: center;
                }
                .ex-icon{
                    width: 18px;
                    margin-right: 2px;
                }
                .b-item-line{
                    height: 100%;
                    padding: 3px 0px;
                    position: absolute;
                    width: 2px;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    margin: auto;
                    .line{
                        height: 100%;
                        width: 100%;
                        background: #F5F5F5;
                    }
                }
            }
            .result-item.c{
                margin-top: 19px;
                .com-dia-button+ .com-dia-button{
                    margin-top: 12px;
                }
            }
        }
    }
</style>